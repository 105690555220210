import SEO from "../../SEO_mapping.json";
// Update lead field
export const setLeadField = (props, value) => {
  if (props.field) {
    const lead = { ...props.lead };

    // If there is a cartIndex property then this property is from a cart item
    let obj = getObject(props.field, lead);
    if ("cartIndex" in props) {
      obj = getObject(props.field, lead.cart[props.cartIndex]);
    }

    //Set the field in the object
    obj.obj[obj.field] = value;

    // Update this value to the server
    props.updateLead(props.magicLinkToken, lead);
  }
};

// Get lead field
export const getLeadField = props => {
  if (props.field) {
    // Get the right object
    let obj = getObject(props.field, props.lead);
    if ("cartIndex" in props) {
      obj = getObject(props.field, props.lead.cart[props.cartIndex]);
    }

    // Return object value
    if (obj.obj) {
      return obj.obj[obj.field];
    }
  }
  return null;
};

// Get the object to set the custom property (eg: "pickupAddress.zip" will return pickupAddress object from initialObject)
function getObject(field, initialObject) {
  const parts = field.split(".");
  let obj = initialObject;
  let i = 0;
  for (; i < parts.length - 1; i++) {
    if (!obj[parts[i]]) {
      obj[parts[i]] = {};
    }
    obj = obj[parts[i]];
  }
  return { obj, field: parts[i] };
}

// Get uploaded file url
export const getFileUrl = file => {
  const fileUrl =
    file.meta.host +
    file._downloadRoute +
    "/" +
    file._collectionName +
    "/" +
    file._id +
    "/original/" +
    file._id +
    file.extensionWithDot;
  return fileUrl
    .replace(/\/\//g, "/")
    .replace("http:/", "http://")
    .replace("https:/", "https://");
};

// Transfrom data from ProductList APi to price table format
export const getPrices = (productList, categoryId) => {
  let priceTableData;
  if (productList && productList.length) {
    priceTableData = productList.filter(item => {
      const parentCategories = item["parent_categories"];
      if (parentCategories && parentCategories.some(e => e.id === categoryId))
        return true;
    });
  }
  if (priceTableData) {
    return priceTableData.map(item => ({
      name: item.name,
      slug: item.slug,
      price: item.price.slice(0, 3),
    }));
  } else {
    return [];
  }
};

// Get today's date in DD.MM.YYYY format
export const getToday = () =>
  new Date()
    .toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "/");

export const redirect = url => {
  if (typeof window !== "undefined") {
    window.location.href = url;
  }
};

export const isLocalhost = () => {
  if (typeof window !== "undefined") {
    const url = window.location;
    if (url.host.match(/^localhost(:\d{4})?$/)) {
      return true;
    }
  }
  return false;
};

export const getDEURL = () => {
  if (typeof window !== "undefined") {
    const url = window.location;
    const newHost = url.host.replace("schrott24.ch", "schrott24.de");
    return `${url.protocol}//${newHost}${url.pathname}${url.search}`;
  }
};

export const getSEO = (page, domain) => {
  if (!page) page = "default";

  let seo = SEO[page] || SEO["default"];
  let compiledSeo = { ...SEO["default"], ...seo };
  for (let item in compiledSeo) {
    if (item !== "languageAlternates")
      compiledSeo[item] = { ...SEO["default"][item], ...compiledSeo[item] };
  }

  for (let item in compiledSeo) {
    if (item !== "languageAlternates")
      compiledSeo[item] = compiledSeo[item][domain] || compiledSeo[item]["de"];
  }
  return compiledSeo;
};
